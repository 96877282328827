<template>
  <b-modal
    @shown="onModalShown"
    title="編輯在地養成公費生基本資料"
    id="localStudentDataEdit"
    size="xl"
    @hide="doSometing"
  >
  
    <b-container fluid>
      <main class="main-grid-dtc">
        <b-input-group prepend="姓名">
          <b-input v-model="item.name" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="身分證字號">
          <b-input v-model="item.id" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="出生日期">
          <b-input v-model="item.birthday" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="性別">
          <b-input v-model="item.gender" readonly></b-input>
        </b-input-group>
        <!-- <b-input-group prepend="籍別"><b-input v-model="item.domicile" readonly></b-input></b-input-group>-->
        <!-- <b-input-group prepend="籍貫"><b-input v-model="item.hometown" readonly></b-input></b-input-group> -->
        <!-- <b-input-group prepend="科別"><b-input v-model="item.cat" readonly></b-input></b-input-group> -->
        <b-input-group prepend="科別"><b-select :options="majorObject" v-model="item.cat"></b-select></b-input-group>
        <b-input-group prepend="入學年">
          <b-input v-model="item.entryYear" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="培育學校">
          <b-input v-model="item.school" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="行動電話" class="mobilephone">
          <div></div>
          <div>
            <b-input
              v-model="mobilephone.number1"
              placeholder="09XX"
              @keypress="isNumber($event)"
            ></b-input>
          </div>
          <div class="dash">-</div>
          <div>
            <b-input
              v-model="mobilephone.number2"
              placeholder="000"
              @keypress="isNumber($event)"
            ></b-input>
          </div>
          <div class="dash">-</div>
          <div>
            <b-input
              v-model="mobilephone.number3"
              placeholder="000"
              @keypress="isNumber($event)"
            ></b-input>
          </div>
        </b-input-group>
        <b-input-group prepend="畢業年">
          <b-input v-model="item.graduateYear"  @keypress="isNumber($event)" ></b-input>
        </b-input-group>
        <b-input-group prepend="市話" class="telphone">
          <div></div>
          <div>
            <b-input
              v-model="telphone.number1"
              placeholder="區號"
              @keypress="isNumber($event)"
            ></b-input>
          </div>
          <div class="dash">-</div>
          <div>
            <b-input
              v-model="telphone.number2"
              placeholder="電話"
              @keypress="isNumber($event)"
            ></b-input>
          </div>
        </b-input-group>
        <b-input-group prepend="學號" style="grid-column: span 1">
          <b-input v-model="item.studentID"></b-input>
        </b-input-group>
        <b-input-group prepend="年級">
          <b-select
            :options="GradeStatusObject"
            v-model="item.grade"
            style="height: 38px; font-size: 1rem !important"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="戶籍地址" style="grid-column: 1/-2">
          <b-input v-model="item.homeAddress"></b-input>
        </b-input-group>
        <b-input-group prepend="返鄉年度" style="grid-column: span 1">
          <b-input v-model="item.yearofReturn"></b-input>
        </b-input-group>
        <b-input-group prepend="通訊地址" style="grid-column: 1/-2">
          <b-input v-model="item.contactAddress"></b-input>
        </b-input-group>
        <b-input-group prepend="合約" style="grid-column: span 1">
          <b-input 
            readonly 
            value="查看PDF"
            @click="openContract(item)"
          ></b-input>  
        </b-input-group>
        <b-input-group prepend="E-MAIL" style="grid-column: 1/-1">
          <b-input v-model="item.email"></b-input>
        </b-input-group>
        <b-input-group prepend="服務情形">
          <b-select
            @change="setServiceOption"
            :options="localStatusObject"
            v-model="item.status"
            style="height: 38px; font-size: 1rem !important"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="服務現況" style="grid-column: span 2">
          <b-select
            :options="serviceOption"
            v-model="item.servicenow"
            style="height: 38px; font-size: 1rem !important"
          ></b-select>
        </b-input-group>
        <b-input-group
          prepend="預計休學開始日期"
          class="will-graduate"
          v-if="isDropOutSchool"
        >
          <div>
            <b-select
              :options="yearsObject"
              v-model="willGraDate.year"
              style="height: 38px; font-size: 1rem !important"
            ></b-select>
          </div>
          <div class="word">年</div>
          <div class="word">-</div>
          <div>
            <b-select
              v-show="item.servicenow == '休學'"
              :options="monthsObject"
              v-model="constMonth"
              style="height: 38px; font-size: 1rem !important"
            ></b-select>
          </div>
          <div class="word">月</div>
        </b-input-group>
        <b-input-group
          prepend="預計休學結束日期"
          class="will-graduate"
          v-if="isDropOutSchool"
        >
          <div>
            <b-select
              :options="yearsObject"
              v-model="ednDate.year"
              style="height: 38px; font-size: 1rem !important"
            ></b-select>
          </div>
          <div class="word">年</div>
          <div class="word">-</div>
          <div>
            <b-select
              v-show="item.servicenow == '休學'"
              :options="monthsObject"
              v-model="endMonth"
              style="height: 38px; font-size: 1rem !important"
            ></b-select>
          </div>
          <div class="word">月</div>
        </b-input-group>

        <b-input-group
          :prepend="`${isExtendedGraduation ? '預計畢業日期' : '退學日期'}`"
          class="will-graduate"
          v-if="(isExtendedGraduation || isNoSchoolNow) && item.servicenow == '延畢'"
        >
          <div>
            <b-select
              :options="yearsObject"
              v-model="willGraDate.year"
              style="height: 38px; font-size: 1rem !important"
            ></b-select>
          </div>
          <div class="word">年</div>
          <div class="word">-</div>
          <div>
            <b-input
              v-show="item.servicenow == '延畢'"
              v-model="constMonth"
              class="word2"
              readonly
            ></b-input>
            <b-select
              v-show="item.servicenow == '退學'"
              :options="monthsObject"
              v-model="constMonth"
              style="height: 38px; font-size: 1rem !important"
            ></b-select>
          </div>
          <div class="word">月</div>
        </b-input-group>

        <b-input-group
          prepend="是否領取公費生補助計畫經費"
          style="grid-column: span 2"
          class="special-col"
          v-if="isExtendedGraduation"
        >
          <b-form-radio-group v-model="item.willGetMoney" class="ml-3 mt-2">
            <b-form-radio value="Y">是</b-form-radio>
            <b-form-radio value="N">否</b-form-radio>
          </b-form-radio-group>
        </b-input-group>
        <b-input-group
          prepend="休學原因"
          style="grid-column: 1/-1"
          v-if="isDropOutSchool"
        >
          <b-input v-model="item.dropOutReason"></b-input>
        </b-input-group>

        <b-input-group prepend="備註" style="grid-column: 1/-1">
          <b-form-textarea
            id="textarea"
            v-model="item.originRemark"
            placeholder="請輸入備註..."
            rows="4"
            max-rows="6"
          ></b-form-textarea>
        </b-input-group>
      </main>
    </b-container>
    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headers"
        :key="`headers${i}`"
        :title="item.name || item"
        @click="sort(item)"
      >
        {{ item.name || item }}
        <span v-show="item.sortDesc === null">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color: #39312e"
      :style="i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'"
    >
      <div>
        {{ i + 1 }}
      </div>
      <div :title="item.UserName">
        {{ item.UserName }}
      </div>
      <div :title="item.ServiceSituation">
        {{ item.ServiceSituation }}
      </div>
      <div :title="item.Content">
        {{ item.Content }}
      </div>
      <div :title="item.Remark">
        {{ item.Remark }}
      </div>
      <div :title="item.UpdateTime">
        {{ item.UpdateTime }}
      </div>
    </main>
    <template v-slot:modal-footer>
      
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('localStudentDataEdit')"
          >取消</b-button
        >
        <b-button
          variant="success"
          class="float-right ml-3"
          @click="updatePersonInfo"
          >確定</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { yearsObject } from "@/constant.js";
import { store } from "@/store/global.js";
import moment from "moment";
const monthsObject = ["2", "6"];

const headers = [
  "序號",
  "帳號名稱",
  "服務情形",
  "服務現況",
  "備註",
  "異動時間",
];
// const statusOption = [
//   // "未入學",
//   "在學期間",
//   "醫師證書",
//   "訓練期間",
//   "服務期間",
//   "服務期滿",
//   "展緩期間",
//   "履約終止",
// ];
// const allServiceOption = {
//   option1: ["未入學"],
//   option2: ["在學中", "休學", "延畢"],
//   option3: ["畢業"],
//   option4: ["PGY訓練", "專科訓練", "訓練中", "進修中"],
//   option5: ["服務中", "進修中"],
//   option6: ["留任", "不留任", "退休", "死亡"],
//   option7: ["未考照", "考照未訓", "尚未服務"],
//   option8: ["退學", "還款中", "因病", "死亡"],
// };
import { 
  localStatusObject, 
  allRuralServiceObject,
  GradeStatusObject
} from "@/constant.js";

export default {
  name: "EditPersonItem",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      fullSchool: "",
      item: { ...store.editItem },
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      localStatusObject,
      GradeStatusObject,
      majorObject:[],
      allRuralServiceObject,
      serviceOption: [],
      allServiceOption: [],
      telphone: {
        number1: "",
        number2: "",
      },
      mobilephone: {
        number1: "",
        number2: "",
        number3: "",
      },
      yearsObject,
      willGraDate: {
        year: "",
      },
      monthsObject,
      constMonth: 6,
      nowIndex: 0,

      ednDate: {
        year: "",
      },
      endMonth: 6,
    };
  },
  computed: {
    isExtendedGraduation() {
      return (
        Boolean(this.item.servicenow) && Boolean(this.item.servicenow == "延畢") || Boolean(this.item.servicenow) && Boolean(this.item.servicenow == "在學中")
      );
    },
    isDropOutSchool() {
      return (
        Boolean(this.item.servicenow) && Boolean(this.item.servicenow == "休學")
      );
    },
    isNoSchoolNow() {
      return (
        Boolean(this.item.servicenow) && Boolean(this.item.servicenow == "退學")
      );
    },
  },
  methods: {
    async onModalShown() {
      this.updateItem();
      await this.fetchStudentData();
    },
    sort(item) {
      if (!Boolean(item.name)) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];
      this.headers.forEach((s) => {
        if (!Boolean(s.name)) {
          return;
        } else if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });
      this.getData();
    },
    doSometing() {
      this.$emit("updateLocalStuData");
    },
    setServiceOption() {
      let v = this.item.status;
      this.nowIndex = this.nowIndex + 1;
      if (+this.nowIndex > 3) {
        this.serviceOption = this.allRuralServiceObject[v];
        this.item.servicenow = this.allRuralServiceObject[v][0];
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async updatePersonInfo() {
      let obj = { ...this.item.originAllData };
      console.log(this.item);
      const dash =
        Boolean(this.telphone.number1) && Boolean(this.telphone.number2)
          ? "-"
          : "";
      obj.Home = `${this.telphone.number1}${dash}${this.telphone.number2}`;

      const mDash1 =
        Boolean(this.mobilephone.number1) && Boolean(this.mobilephone.number2)
          ? "-"
          : "";
      const mDash2 =
        Boolean(this.mobilephone.number2) && Boolean(this.mobilephone.number3)
          ? "-"
          : "";
      obj.Mobile = `${this.mobilephone.number1}${mDash1}${this.mobilephone.number2}${mDash2}${this.mobilephone.number3}`;

      obj.ResidenceAddrs = this.item.homeAddress;
      obj.ContAddress = this.item.contactAddress;
      obj.Email = this.item.email;
      obj.MajorName = this.item.cat;//科別
      obj.EndYear = this.item.graduateYear;//畢業年
      obj.studentID = this.item.studentID;//畢業年
      obj.YearofReturn = this.item.yearofReturn;
      if (this.item.grade == "一") {
        obj.Grade = "1";
      } else if (this.item.grade == "二") {
        obj.Grade = "2";
      } else if (this.item.grade == "三") {
        obj.Grade = "3";
      } else if (this.item.grade == "四") {
        obj.Grade = "4";
      } else if (this.item.grade == "五") {
        obj.Grade = "5";
      } else if (this.item.grade == "六") {
        obj.Grade = "6";
      } else if (this.item.grade == "七") {
        obj.Grade = "7";
      }else{
        obj.Grade = "";
      }

      if (Boolean(this.item.status)) {
        obj.ServiceItem = this.item.status;
      }
      if (Boolean(this.item.servicenow)) {
        obj.ServiceDetail = this.item.servicenow;
      }

      //休學
      if (Boolean(this.willGraDate.year) && this.isDropOutSchool) {
        obj.RecDate = this.$usDate(
          `${this.willGraDate.year}-0${+this.constMonth}-01`
        );
        obj.EndDate = this.$usDate(
          `${this.ednDate.year}-0${+this.endMonth}-01`
        );
        obj.RecRemark = this.item.dropOutReason;
      }

      //延畢
      if (Boolean(this.willGraDate.year) && this.isExtendedGraduation) {
        obj.RecDate = this.$usDate(`${this.willGraDate.year}-06-01`);
      }
      if (Boolean(this.item.willGetMoney) && this.isExtendedGraduation) {
        obj.GetFee = this.item.willGetMoney == "Y" ? true : false;
      }

      //退學
      if (Boolean(this.willGraDate.year) && this.isNoSchoolNow) {
        obj.RecDate = this.$usDate(
          `${this.willGraDate.year}-0${+this.constMonth}-01`
        );
      }

      obj.Note = this.item.originRemark;
      //console.log('obj',obj);
      try {
        await window.axios.put(`StudentInfo/Put`, obj);
        store.editItem = { ...obj };
        this.$bvToast.toast(`編輯成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.$bvModal.hide("localStudentDataEdit");
      } catch (e) {
        this.$bvToast.toast(e + ``, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getMajor() {
      const arr = await window.axios.get("SysCode/Get?category=major");
      //console.log(arr);
      this.majorObject = arr.map((s) => ({
        value: s.Display,
        text: s.Display,
      }));
      this.majorObject.unshift({ value: null, text: "全部" });
    },
    updateItem() {
      this.nowIndex = 0;
      this.nowIndex = this.nowIndex + 1;
      this.item = {};
      this.item = { ...store.editItem };
      this.willGraDate = {
        year: "",
      };
      this.ednDate = {
        year: "",
      };

      //休學、延畢、退學
      let recdate = this.$twDate2(this.item.originAllData.RecDate);
      let recdateArr = recdate.split("-");
      this.willGraDate.year = recdateArr[0];
      this.constMonth = `${+recdateArr[1]}`;

      if (Boolean(this.item.originAllData.EndDate)) {
        let enddate = this.$twDate2(this.item.originAllData.EndDate);
        let endDateArr = enddate.split("-");
        this.ednDate.year = endDateArr[0];
        this.endMonth = `${+endDateArr[1]}`;
      }
      this.item.willGetMoney = this.item.originAllData.GetFee !== false
        ? "Y"
        : "N";
      this.item.dropOutReason = Boolean(this.item.originAllData.RecRemark)
        ? this.item.originAllData.RecRemark
        : "";

      //mobile
      const telphoneArr = this.item.telphone.split("-");
      this.telphone.number1 = Boolean(telphoneArr[0]) ? telphoneArr[0] : "";
      this.telphone.number2 = Boolean(telphoneArr[1]) ? telphoneArr[1] : "";

      let mobileDashNum = this.countDashNum(this.item.mobilephone);
      if (+mobileDashNum == 0) {
        if (!Boolean(this.item.mobilephone)) {
          this.mobilephone.number1 = "";
          this.mobilephone.number2 = "";
          this.mobilephone.number3 = "";
        } else {
          this.mobilephone.number1 = this.item.mobilephone.substring(0, 4);
          this.mobilephone.number2 = this.item.mobilephone.substring(4, 6);
          this.mobilephone.number3 = this.item.mobilephone.substring(6, 8);
        }
      } else if (+mobileDashNum == 1) {
        const mobilephoneArr = this.item.mobilephone.split("-");
        this.mobilephone.number1 = Boolean(mobilephoneArr[0])
          ? mobilephoneArr[0]
          : "";
        this.mobilephone.number2 = mobilephoneArr[1].substring(0, 3);
        this.mobilephone.number3 = mobilephoneArr[1].substring(3, 6);
      } else if (+mobileDashNum == 2) {
        const mobilephoneArr = this.item.mobilephone.split("-");
        this.mobilephone.number1 = Boolean(mobilephoneArr[0])
          ? mobilephoneArr[0]
          : "";
        this.mobilephone.number2 = Boolean(mobilephoneArr[1])
          ? mobilephoneArr[1]
          : "";
        this.mobilephone.number3 = Boolean(mobilephoneArr[2])
          ? mobilephoneArr[2]
          : "";
      }
      //status
      let v = this.item.status;
      if (Boolean(v)) {
        this.serviceOption = this.allRuralServiceObject[v];
      }
    },
    countDashNum(word) {
      const arr = word.split("");
      let coumt = 0;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] == "-") {
          coumt += 1;
        }
      }
      return coumt;
    },
    async fetchStudentData() {
      //console.log(this.item.id);
      try {
        const response = await axios.get(`StudentInfo/GetStudentIndex?Identifier=${this.item.id}`);
        //console.log(response);
        this.items = response.map((s) => ({
          Identifier: s.Identifier || "",
          AbmoveName: s.AbmoveName || "",
          UserName: s.UserName || "",
          ServiceSituation: s.ServiceSituation || "",
          Content: s.Content || "",
          Remark: s.Remark || "",
          UpdateTime: s.UpdateTime || "",
        }));
        console.log(this.items);
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    },
    openContract(item) {
      store.editItem = { ...item };
      store.nowIndex = 21;
      this.$router.push("contractview");
    },
  },
  async mounted() {
    await this.getMajor();
  },
  beforeUpdate() {
    this.nowIndex = this.nowIndex + 1;
  },
  watch: {
    "telphone.number1"(v) {
      let value = v;
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.telphone.number1 = result));
    },
    "telphone.number2"(v) {
      let value = v;
      if (v.length > 8) {
        value = value.substring(1, 9);
      }
      const result = value;
      this.$nextTick(() => (this.telphone.number2 = result));
    },
    "mobilephone.number1"(v) {
      let value = v;
      if (v.length > 4) {
        value = value.substring(1, 5);
      }
      const result = value;
      this.$nextTick(() => (this.mobilephone.number1 = result));
    },
    "mobilephone.number2"(v) {
      let value = v;
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.mobilephone.number2 = result));
    },
    "mobilephone.number3"(v) {
      let value = v;
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.mobilephone.number3 = result));
    },
    "item.servicenow"(v) {
      if (v == "延畢") {
        this.constMonth = 6;
      }
    },
    "item.willGraDate"(v) {
      let time = moment(v).format("YYYY");
      this.item.graduateYear = +time - 1911;
    },
  },
};
</script>

<style scoped lang="scss">
  .dtc-grid-header,
  .dtc-grid-header2 {
    width: 1060px;
    display: grid;
    grid-template-columns:
      100px repeat(2, 150px) 300px 180px 180px;
      /*repeat(5, 80px) repeat(12, 120px) repeat(2, 200px) 130px 150px
      160px
      380px;*/
    grid-auto-flow: column;
    text-align: center;
    border-right: 0px;
    margin-left: 15px;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      /* word-break: keep-all; */
      -webkit-box-orient: vertical;
      font-size: 14px;
      padding: 4px 0;
      border-right: 1px solid #dee2e5;
      border-bottom: 1px solid #dee2e5;
      display: -webkit-box;
      height: 36px;
    }
    > div:first-child {
      border-left: 1px solid #dee2e5;
    }
  }

  .dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

  .my-dark {
    > div {
      // background: var(--gray);
      background: #424242;
      color: white;
    }
  }

  .dtc-grid-header {
    > div {
      line-height: 30px;
    }
  }

  p {
    font-size: 2em;
    text-align: center;
  }
  .input-group-text {
    width: 150px;
    background: #0379fd;
    color: #fff;
  }
  div.input-group.special-col > div.input-group-prepend > div.input-group-text {
    width: 300px;
  }

  .code-dtc {
    display: grid;
    grid-template-columns: 60px minmax(400px, 525px);
  }
  .input-group {
    margin-bottom: 10px;
  }
  .main-grid-dtc {
    display: grid;
    grid-template-columns: 350px 350px 1fr;
    column-gap: 5px;
  }
  .main-grid3-dtc {
    display: grid;
    grid-template-columns: 350px 350px 1fr;
  }

  ::v-deep
    #localStudentDataEdit
    > .modal-dialog
    > .modal-content
    > .modal-header {
    background: #7e2ca3;
    color: #ffffff;
    .close {
      color: #ffffff;
    }
  }

  .telphone {
    display: grid;
    grid-template-columns: 150px 3px 58px 12.5px 126px;
    .dash {
      margin-top: 7px;
      text-align: center;
    }
  }
  .mobilephone {
    display: grid;
    grid-template-columns: 150px 3px 62px 12.5px 54px 12.5px 54px;
    .dash {
      margin-top: 7px;
      text-align: center;
    }
  }
  .will-graduate {
    display: grid;
    grid-template-columns: 150px 90px 24px 12.5px 60px 15px;
    .word {
      margin-top: 7px;
      text-align: center;
    }
    .word2 {
      border-radius: 0.25rem;
      text-align: center;
    }
  }
</style>
